export const AUTH_TOKEN_STORAGE_KEY = 'MONITE_AUTH_TOKEN';

const isLocalhost = (url: string) =>
  url.includes('localhost') || url.includes('127.0.0.1');

export const APP_BASE_URL = isLocalhost(window.location.origin)
  ? process.env.REACT_APP_DEFAULT_API_URL
  : '/api/v1';

export const SCREEN_TEST_MODE = 'screentest';

export const ROUTES = {
  signin: '/signin',
  signup: '/signup',

  reset: '/reset',
  resetCheck: '/reset/check',
  resetPassword: '/reset/password',
  resetToken: '/reset/:token',
  resetTokenDemo: '/reset/token1234',

  confirm: '/confirm',
  activationPending: '/activation-pending',
  confirmToken: '/confirm/:token',
  confirmTokenDemo: '/confirm/token1234',

  joinToken: '/join/:token',

  projects: '/projects',
  apiKeys: '/projects/:id/keys',
  apiKeysDemo: '/projects/project1234/keys',
  projectSettings: '/projects/:id/settings',
  projectNew: '/projects/new',

  users: '/users',
  user: '/users/:id',

  companyEdit: '/companies/:id/edit',

  deletedAccount: '/deleted',

  termsAndConditions: '/terms',
};
