import React from 'react';
import { Button, Card } from '@monite/ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useAbility } from '@casl/react';
import { Tooltip } from '@monite/ui';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import { ROUTES } from 'features/app/consts';
import { useProjects } from 'features/projects/api';

import Layout, { PageHeader, PageContent } from 'features/app/Layout';
import Project from 'features/projects/Project';
import {
  Can,
  RoleSubjects,
  RoleActions,
  AbilityContext,
} from 'features/roleModel';

import styles from './styles.module.scss';

const PageProjects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as any;

  const { isLoading, data: projects } = useProjects();

  const ability = useAbility(AbilityContext);

  const onClickNewProject = () => {
    navigate(ROUTES.projectNew);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Layout title={t('projects:list.title')} loading={isLoading}>
      <Tooltip />
      <PageHeader title={t('projects:list.title')}>
        <Can I={RoleActions.CREATE} a={RoleSubjects.PROJECT}>
          <Button
            onClick={onClickNewProject}
            leftIcon={<PlusIcon width={24} height={24} />}
            text={t('projects:list.new')}
          />
        </Can>
      </PageHeader>
      <PageContent>
        {(projects || []).length ? (
          <div className={styles.projects}>
            {(projects || []).map((project) => (
              <Project
                key={project.id}
                data={project}
                isHighlight={state?.highlightProjectId === project.id}
              />
            ))}
          </div>
        ) : (
          <Card className={styles.empty}>
            <h2>{t('projects:empty.title')}</h2>
            <div>{t('projects:empty.text')}</div>
            <Button
              disabled={!ability.can(RoleActions.CREATE, RoleSubjects.PROJECT)}
              text={t('projects:empty.button')}
              onClick={() => navigate('/projects/new')}
              tooltip={{
                tip: !ability.can(RoleActions.CREATE, RoleSubjects.PROJECT)
                  ? t('projects:createDisabledTooltip')
                  : '',
              }}
            />
          </Card>
        )}
      </PageContent>
    </Layout>
  );
};

export default PageProjects;
