import { FormikProps } from 'formik';
import { Button, FormField, Input } from '@monite/ui';
import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.scss';

export type FormValues = {
  name: string;
};

interface BaseFormProps extends FormikProps<FormValues> {
  inProgress: boolean;
}

const BaseForm = (props: BaseFormProps) => {
  const {
    inProgress,
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
  } = props;

  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  return (
    <form method="post" onSubmit={handleSubmit}>
      <FormField
        id="name"
        label={t('projects:new.name')}
        error={errors.name && touched.name ? errors.name : ''}
      >
        <Input
          id="name"
          required
          isInvalid={Boolean(errors.name && touched.name)}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>
      {isSubmitting ? (
        <Button type="submit" disabled isLoading />
      ) : (
        <Button
          type="submit"
          text={t('projects:new.submit')}
          className={cn(styles.creatingButton, {
            [styles.hover]: isHover || inProgress,
            [styles.inprogress]: inProgress,
          })}
          onPointerEnter={() => {
            setIsHover(true);
          }}
          onPointerOut={() => {
            setIsHover(false);
          }}
          tooltip={{
            tip:
              !dirty || !isValid
                ? t('projects:new.validationTip')
                : t('projects:new.submitTip'),
          }}
          disabled={!dirty || !isValid || isSubmitting}
        />
      )}
    </form>
  );
};

export default BaseForm;
