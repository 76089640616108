import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState, useRef } from 'react';
import { useTranslation, TFunction } from 'react-i18next';

import { createProject } from 'features/projects/api';
import { ROUTES } from 'features/app/consts';
import { AxiosError, ProjectsRequest } from 'features/types';

import BaseForm, { FormValues } from './Form';
import { projectFormResponseErrorHandling } from '../../shared/projectFormResponseErrorHandling';

const getValidationSchema = ({ t }: { t: TFunction }) => {
  const validators = {
    name: Yup.string().required(
      t('forms:errors.required', { field: t('projects:new.name') })
    ),
  };

  return Yup.object().shape(validators);
};

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const createProjectMutation = useMutation({
    mutationFn: (data: ProjectsRequest) => createProject(data),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ['projects'] });
      if (data) {
        navigate(ROUTES.projects, { state: { highlightProjectId: data.id } });
        toast(
          (data.environment || '').toLowerCase().includes('sandbox')
            ? t('projects:new.createdSandbox', { name: data.name })
            : t('projects:new.createdLive', { name: data.name })
        );
      }
    },
    onError: (error: AxiosError) => {
      if (!formikRef.current) return;

      projectFormResponseErrorHandling(
        error,
        t,
        formikRef.current.setFieldError.bind(formikRef.current.setFieldError)
      );
    },
  });

  const [inProgress, setInProgress] = useState(false);
  let progressTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onFormSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    if (progressTimeout.current) {
      setInProgress(false);
      clearTimeout(progressTimeout.current);
      progressTimeout.current = null;
      return;
    }

    setInProgress(true);

    progressTimeout.current = setTimeout(() => {
      setInProgress(false);
      progressTimeout.current = null;

      createProjectMutation.mutate({
        name: formData.name,
      });
    }, 5000);
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return <BaseForm inProgress={inProgress} {...props} />;
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={onFormSubmit}
      validationSchema={getValidationSchema({ t })}
      innerRef={formikRef}
    >
      {renderForm}
    </Formik>
  );
};

export default Form;
