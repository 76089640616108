import { Card } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import Layout, { PageHeader, PageContent } from 'features/app/Layout';

import Form from './Form';

import styles from './styles.module.scss';

const PageNewProject = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('projects:new.title')}>
      <PageHeader
        title={t('projects:new.title')}
        breadcrumbs={[{ title: t('projects:new.back'), link: '/projects' }]}
      />
      <PageContent>
        <Card className={styles.card}>
          <div>
            <Form />
          </div>
        </Card>
      </PageContent>
    </Layout>
  );
};

export default PageNewProject;
